<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="12" md="12" lg="12">
        <template>
          <div
            class="d-flex flex-row flex-fill justify-center mt-16 animate__animated animate__fadeIn"
          >
            <img
              :src="require('@/assets/calendar-1.svg')"
              style="width: 120px;"
            />
          </div>
          <div class="d-flex flex-column flex-fill align-center mt-5">
            <span
              class="font font-md font-weight-medium pt-2 animate__animated animate_fadeInDown"
            >
              Download transactions in formats like excel, csv
            </span>

            <v-chip
              color="primary"
              label
              small
              class=" font-sm font font-weight-medium font-size-sm my-2"
              >Instructions to Follow</v-chip
            >
            <span
              class="font font-md font-weight-medium text-center font-size-sm"
              >Choose a single date, click "OK" or <kbd>a date range</kbd> to
              start downloading transactions. <br />Choose an agent to extract
              all transactions specific to that agent</span
            >

            <p>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="dates"
                transition="scale-transition"
                offset-y
                class="font font-weight-medium"
                min-width="auto"
                :nudge-left="120"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-model="dates"
                    v-bind="attrs"
                    v-on="on"
                    label
                    :close="dates.length === 2"
                    @click:close="(dates = []), (chosenDateRange = [])"
                    :color="dates.length > 0 ? 'primary' : 'white'"
                    class=" box-shadow-light mt-5 font text-uppercase font-weight-medium"
                  >
                    <i class="material-icons-outlined font-size-md mr-2"
                      >event</i
                    >
                    {{
                      dates.length > 0
                        ? dateRangeText
                        : 'Choose single date or date range'
                    }}
                  </v-chip>
                </template>
                <v-date-picker
                  class="font font-weight-medium font-size-sm"
                  v-model="dates"
                  :max="new Date().toISOString().substring(0, 10)"
                  range
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    small
                    class="font font-weight-medium"
                    text
                    color="primary"
                    @click="(menu = false), (date = []), (chosenDateRange = [])"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    @click="setFilteredDate"
                    text
                    class="font font-weight-medium"
                    color="primary"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </p>
            <div class="d-flex flex-row flex-grow-1">
              <v-autocomplete
                :items="[{ _id: 'ALL', name: 'ALL' }, ...staff]"
                item-text="name"
                v-model="accountNumber"
                item-value="_id"
                outlined
                dense
                class="font font-sm font-weight-medium mt-2 mb-2"
                label="Choose an agent/staff"
                persistent-hint
                hint="Get all transaction for a particular agent"
              >
                <template #item="{item}">
                  <span class="font font-weight-medium font-sm font-size-md">
                    {{ item.name }}
                  </span>
                </template>
              </v-autocomplete>
            </div>
            <template v-if="chosenDateRange.length > 0">
              <span class=" font font-md font-weight-medium mb-2"
                >Click any of the format to download
              </span>
              <p>
                <v-avatar
                  v-ripple
                  tile
                  @click="
                    isLoading || isInvalidDate ? '' : downloadFile('xlsx')
                  "
                  class="box-shadow-light mx-2"
                  style="cursor:pointer;"
                >
                  <img src="@/assets/excel.png" style="width: 40px;" />
                </v-avatar>
                <v-avatar
                  v-ripple
                  tile
                  @click="isLoading || isInvalidDate ? '' : downloadFile('csv')"
                  class="box-shadow-light mx-2"
                  style="cursor:pointer;"
                >
                  <img src="@/assets/csv.svg" style="width: 40px;" />
                </v-avatar>
              </p>
            </template>
            <v-alert
              type="error"
              dense
              v-if="isInvalidDate"
              outlined
              clearable
              class="font font-weight-medium font-size-md "
            >
              Invalid date provided, kindly check again.
            </v-alert>
            <span v-if="isLoading">
              <v-progress-circular
                indeterminate
                color="primary"
                size="30"
                width="2"
              />
            </span>
          </div>
        </template>
      </v-col>
    </v-row>
    <SnackBar
      :showSnackBar="showSnackBar"
      :message="message"
      :status="status"
    />
  </v-container>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import SnackBarMixins from '../../../../mixins/SnackBarMIxins';
import { loadComponent } from '@/utils/helpers';

export default {
  name: 'ExportTransactions',
  data() {
    return {
      menu: false,
      dates: [],
      chosenDateRange: [],
      isInvalidDate: false,
      accountNumber: 'ALL',
    };
  },
  components: {
    SnackBar: loadComponent(`component/SnackBar`),
  },
  computed: {
    ...mapGetters({
      isLoading: 'getIsLoading',
      staff: 'staffs/getAllStaffs',
    }),
    dateRangeText() {
      return this.dates.join(' ~ ');
    },
  },
  mixins: [SnackBarMixins],
  methods: {
    setFilteredDate() {
      const [startDate, endDate] = this.dates;
      if (moment(startDate).isAfter(moment(endDate))) {
        this.isInvalidDate = true;
        this.$refs.menu.save([]);
      } else {
        this.isInvalidDate = false;
        this.$refs.menu.save(this.dates);
        this.chosenDateRange = this.dates;
      }
    },
    downloadFile(ext) {
      const startDate = this.chosenDateRange[0];
      const endDate = this.chosenDateRange[1] ?? startDate;
      const fileName = `trans-${this.chosenDateRange.join('-')}`;
      this.$store.dispatch('transactions/download', {
        fileName,
        ext,
        startDate,
        endDate,
        ...(this.accountNumber && {
          accountNumber: this.accountNumber,
        }),
      });
    },
  },
  created() {
    this.$store.dispatch('staffs/rawList', `?isPaginated=false`);
  },
};
</script>

<style scoped></style>
